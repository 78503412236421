import path from 'path'
import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

import SchemaOrg from './schema-org'
import { slugify } from '../../../utils'

const SEO = ({ title, description: desc, imageUrl, slug, locale, isBlogPost = false, datePublished = null, dateModified = null, author = null }) => {

  const intl = useIntl()
  const { site: { siteMetadata: { seo, title: siteTitle, siteUrl } }, fallbackImage } = useStaticQuery(query)

  const description = desc || intl.formatMessage({ id: "seoFallbackDescription" })
  const image = imageUrl || fallbackImage.nodes.filter(img => img.node_locale === locale)[0].fixed.src || "/fallback-image.jpg"
  const url = slug
    ? `${seo.canonicalUrl}${path.sep}${locale}${slugify(slug)}`
    : seo.canonicalUrl

  return (
    <>
      <Helmet>
        {/* General tags */}
        <html lang={locale} />
        <title>{title && title !== siteTitle ? `${title} · ${siteTitle}` : siteTitle}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        {seo.social.fbAppID.length > 3 ? <meta property="fb:app_id" content={seo.social.fbAppID} /> : null}
        {locale === "de" ? <meta property="og:locale" content="de_DE" /> : null}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.social.twitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={title}
        image={image}
        description={description}
        datePublished={datePublished}
        dateModified={dateModified}
        canonicalUrl={seo.canonicalUrl}
        author={author}
        organization={{
          url: siteUrl,
          name: siteTitle,
          logo: `${siteUrl}/favicon.png`
        }}
        defaultTitle={siteTitle}
      />
    </>
  )
}

const query = graphql`
{
  site {
    siteMetadata {
      title
      seo {
        canonicalUrl
        social {
          twitter
          fbAppID
        }
      }
      siteUrl
    }
  }
  fallbackImage: allContentfulAsset(filter: {contentful_id: {eq: "4rzc0hkQO6tS3K4vXaGa6H"}}) {
    nodes {
      node_locale
      fixed(width: 1200, height: 630, quality: 90, resizingBehavior:FILL) {
        src
      }
    }
  }
}
`

export default SEO